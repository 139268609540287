import { useEffect } from 'react'

function ContentWarning(props) {

    // Props
    const setShowContentWarning = props.setShowContentWarning

    useEffect(() => {
        if (sessionStorage.getItem('mature_content_consent') === 'true'){
            setShowContentWarning(false)
        }
    })

    function handleClick(e) {
        e.preventDefault()
        sessionStorage.setItem('mature_content_consent', 'true')
        setShowContentWarning(false)
    }

    return (
        <div className="ContentWarning">
            <div className="contetnWarning-cont">
                <h1 className="contetnWarning-title">Mature Content Warning</h1>
                <h2 className="contentWarning-text">This application is exclusively for explicit content. By proceeding, you confirm that you are at least eighteen years old and understand that the content may include explicit language, nudity, or other adult-oriented interactions. If you are not of legal age or find such content offensive, please exit the application now.</h2>
                <button type="button" className="btn-red contentWarning-button" onClick={(e) => handleClick(e)}>Yes, I am 18+</button>
            </div>
        </div>
    )
}

export default ContentWarning