import { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PopUp from '../common/PopUp'
import trackingEvents from '../utils/trackingEvents'

function Welcome() {

    let navigate = useNavigate()
    const location = useLocation()
    const currentPath = location.pathname

    // Refs
    const readMoreRef = useRef()
    const videoTestRef = useRef()

    // State
    const [ helmetContent, setHelmetContent ] = useState()
    const [ welcomeText, setWelcomeText ] = useState()
    const [ readMoreContent, setReadMoreContent ] = useState()

    useEffect(() => {

        
        switch(currentPath) {
            case '/welcome':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - E-sex with strangers</title>
                        <meta name="description" content="Discover the best free adult chat platform for intimate conversations and thrilling chat sex. Join our webcam sex community for exciting adult chat and explore your sexual fantasies discreetly. Match with hot guys and girls, indulge in passionate foreplay and engage in safe and consensual sexting. Experience the ultimate adult chat experience today!" />
                    </Helmet>
                )
                setWelcomeText('Free platform for webcam e-sex with a random strangers from the comfort of your home')
                setReadMoreContent(
                    <>
                        <p>Welcome to our revolutionary <Link to='/adult-chat'>adult chat</Link> platform, where you can explore your deepest desires in a safe and liberating environment. As the only truly free platform in the <Link to='/chat-sex' landing="chat-sex">chat sex</Link> industry, we offer an unparalleled experience that doesn't cost a dime. Say goodbye to restrictive paywalls and hello to endless possibilities.</p>
                        <p>Our platform is completely free, and there's no sign-up required. We believe in instant gratification, providing you with immediate access to a diverse community of individuals eager to engage in intimate conversations, <Link to='/webcam-sex'>webcam sex</Link>, and <Link to='/sexting'>sexting</Link>. Whether you're seeking thrilling connections, passionate exchanges, or simply a stimulating chat, our platform is your playground.</p>
                        <p>Experience the thrill of voyeurism as you engage in exciting sexual encounters with strangers from all walks of life. Whether you're seeking flirty exchanges, seductive role-playing, or a shared journey of exploration, our platform caters to your voyeuristic desires. Indulge in the excitement of watching and being watched, creating a thrilling dynamic that heightens the sexual experience.</p>
                        <p>With unlimited matching, you have the freedom to connect with as many horny individuals as you desire. We understand that preferences vary, which is why we cater to all orientations and genders. Whether you're looking for an <Link to='/omegle-adult-alternative'>Omegle adult alternative</Link>, <Link to='/gay-chat'>gay chat</Link>, <Link to='/lesbian-chat'>lesbian chat</Link>, or exploring other exciting possibilities, our platform ensures that there's something for everyone.</p>
                        <p>We pride ourselves on offering an alternative to traditional pornography. Instead of passive consumption, our platform encourages active engagement and human connection. Connect with others who share your desires and engage in mutual masturbation, taking pleasure to a new level of connection and shared intimacy.</p>
                        <p>Experience a new way to satisfy your sexual cravings, breaking away from the mundane and embracing the freedom to explore your fantasies. Join our vibrant community today, where pleasure knows no boundaries and the possibilities are limitless. Unleash your desires and embark on an unforgettable journey of adult exploration with our free <Link to='/chat-sex'>chat sex</Link> platform.</p>
                    </>
                )
                break
            case '/chat-sex':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - Free chat sex platform</title>
                        <meta name="description" content="Immerse yourself in scintillating chat sex encounters with hot guys and girls on our webcam sex platform. Enjoy one-on-one interactions, anonymous connections, and explore our captivating sex chat rooms for the best free adult chat experiences." />
                    </Helmet>
                )
                setWelcomeText('Experience the best free chat sex platform')
                setReadMoreContent(
                    <>
                        <p>Indulge in the best chat sex experiences that will leave you breathless. On our platform, we offer an unrivaled space where you can connect with hot guys and girls from around the world. Engage in exhilarating live webcam sessions, one-on-one interactions, and explore our vibrant sex chat rooms. It's time to unleash your deepest desires and ignite your passions in the best way possible.</p>
                        <p>Experience the intensity of live webcam interactions with our diverse community of captivating individuals. Engage in intimate conversations and unleash your fantasies in real time. Our platform provides a secure and anonymous environment, allowing you to explore your desires without hesitation. Whether you seek sultry seduction or wild adventures, our live webcam feature brings your fantasies to life with every tantalizing interaction.</p>
                        <p>In our chat sex realm, you have the privilege of forging intimate connections on a one-on-one basis. Discover the joy of sharing your deepest desires, flirting, and building connections with like-minded individuals. Dive into scintillating conversations that captivate your senses and leave you craving for more. Our platform empowers you to express your true self and explore your sexuality with someone who truly understands your desires.</p>
                        <p>Our sex chat rooms provide a haven for passionate encounters, where you can connect with others anonymously. Embrace the allure of anonymous interactions as you dive into a world of excitement and exploration. Engage in heated discussions, exchange sensual messages, and discover shared interests with fellow members. With our anonymous sex chat rooms, you have the freedom to be your uninhibited self and connect with others seeking the same levels of pleasure.</p>
                        <p>We take pride in providing the best free chat sex experiences that surpass your expectations. Unlock a world of pleasure and sensual connections within our vibrant community. Our platform offers a seamless and user-friendly interface, ensuring that your journey is both enjoyable and effortless. With our exceptional features, passionate individuals, and commitment to excellence, we're here to redefine your perception of chat sex and elevate your satisfaction to new heights.</p>
                    </>
                )
                break
            case '/webcam-sex':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - Free webcam sex platform</title>
                        <meta name="description" content="test" />
                    </Helmet>
                )
                setWelcomeText('Experience the best free webcam sex platform')
                setReadMoreContent(
                    <>
                        <p>Prepare to immerse yourself in a world of seduction, pleasure, and raw passion with our enticing webcam sex platform. Discover the best webcam sex experiences that will awaken your senses and fulfill your deepest desires. Engage in intimate one-on-one interactions with captivating individuals who are eager to share their sensuality with you. It's time to step into a world where your fantasies come to life.</p>
                        <p>Experience the heat and intensity of webcam sex performances that will leave you craving more. Our platform hosts a diverse range of captivating performers who specialize in the art of seduction. Immerse yourself in their alluring presence as they tease, tantalize, and bring your fantasies to fruition through live webcam sessions. Allow yourself to be captivated by their sensuality and explore new realms of pleasure that can only be found in the world of webcam sex.</p>
                        <p>With our webcam sex platform, you have the opportunity to engage in intimate one-on-one interactions that cater to your unique desires. Connect with alluring individuals who are ready to explore your fantasies and share their own. Whether you seek passionate conversations, seductive role-playing, or explicit exchanges, our platform provides a discreet and secure space for you to indulge in uninhibited pleasure. Let your imagination run wild as you embark on a personalized journey of webcam sex exploration.</p>
                        <p>Embark on an unforgettable webcam sex adventure by joining our platform for free. We believe in providing access to exceptional experiences without any barriers. Create an account and immerse yourself in a world of seductive performances, intimate connections, and mind-blowing encounters. Our user-friendly interface ensures that your journey is seamless, allowing you to focus on exploring your desires. Prepare to be captivated, aroused, and seduced in ways you've never imagined.</p>
                        <p>Unlock the gateway to sensuality and let your desires run wild in the realm of webcam sex. Our platform offers a tantalizing escape where you can connect with captivating individuals, indulge in intimate moments, and discover new levels of pleasure. Immerse yourself in the electrifying world of webcam sex and embark on a journey that will leave you breathless. Join us today and experience the ultimate fusion of passion, connection, and uninhibited exploration.</p>
                    </>
                )
                break
            case '/adult-chat':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - Free adult chat platform</title>
                        <meta name="description" content="Immerse yourself in scintillating adult chat encounters with like-minded individuals from around the world. Explore our vibrant adult chat rooms, engage in intimate conversations, and ignite your passions in a safe and inclusive environment. Join our platform for free and unlock the unlimited possibilities of adult chat today." />
                    </Helmet>
                )
                setWelcomeText('Experience the best free adult chat platform')
                setReadMoreContent(
                    <>
                        <p>Step into a world of limitless possibilities and indulgence with our captivating adult chat platform. Whether you seek passionate connections, stimulating conversations, or thrilling encounters, our platform provides a safe and inclusive space for adults to explore their desires. Engage with like-minded individuals from around the globe and embark on a journey of sensual exploration that will leave you breathless.</p>
                        <p>Immerse yourself in a diverse and vibrant community of individuals who are eager to engage in intimate conversations. Our adult chat rooms serve as a hub for open-minded discussions, where you can share your deepest fantasies, exchange seductive messages, and connect with others who share your desires. Discover a realm where you can express yourself freely, interact with fascinating individuals, and explore new levels of pleasure through the power of words.</p>
                        <p>At our adult chat platform, we prioritize your safety and create an inclusive environment where everyone can feel comfortable expressing their desires. We foster a community that embraces diversity, respect, and consensual interactions. Whether you're seeking casual encounters, meaningful connections, or simply want to explore your sensual side, our platform provides a space where you can unleash your passions without judgment.</p>
                        <p>Embark on a thrilling adventure of adult chat by joining our platform for free. Create an account and gain access to an array of features designed to enhance your experience. Engage in stimulating conversations, connect with captivating individuals, and explore a world of adult chat possibilities that will leave you wanting more. Our user-friendly interface ensures that you can navigate seamlessly through the platform, allowing you to focus on connecting and indulging in unforgettable moments.</p>
                        <p>Experience the unrivaled freedom and excitement of adult chat on our platform. Unleash your desires, connect with intriguing individuals, and embrace the uninhibited nature of adult conversations. Whether you're looking for steamy exchanges, intellectual discussions, or simply want to broaden your horizons, our platform offers a gateway to a world of possibilities. Join us today and immerse yourself in the captivating realm of adult chat that knows no boundaries.</p>
                    </>
                )
                break
            case '/sexting':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - Free sexting platform</title>
                        <meta name="description" content="Explore the exhilarating world of sexting and indulge in intimate exchanges with like-minded individuals. Join our platform for free and ignite your desires through seductive messages, provocative conversations, and thrilling encounters. Unleash your fantasies and engage in electrifying sexting sessions today." />
                    </Helmet>
                )
                setWelcomeText('Experience the best free video sexting platform')
                setReadMoreContent(
                    <>
                        <p>Immerse yourself in the enticing realm of sexting and let your fantasies come to life through seductive exchanges. Our platform offers a space where you can connect with like-minded individuals who share your passion for intimate conversations. Engage in provocative exchanges, unleash your desires, and experience the thrilling sensation of sexting that will leave you craving for more.</p>
                        <p>Indulge in the art of seduction and ignite your desires through intimate sexting exchanges. Discover a world where words have the power to entice, arouse, and explore your deepest fantasies. Connect with individuals who share your uninhibited mindset and embark on a journey of pleasure that transcends traditional boundaries. With our platform, you can explore your sensuality and engage in electrifying sexting sessions that leave an indelible mark on your imagination.</p>
                        <p>Unleash your passion and join our platform for free to experience sensational sexting encounters. Create an account and gain access to a community of individuals who are eager to indulge in intimate conversations. Whether you seek casual encounters, discreet connections, or the thrill of exploring new desires, our platform provides a safe and secure environment for your sexting adventures. Don't miss the opportunity to connect with like-minded individuals and discover the intoxicating world of sexting.</p>
                        <p>Unleash your deepest fantasies and engage in provocative conversations with individuals who understand your desires. Our platform allows you to explore your sensual side, push boundaries, and experience the thrill of seductive dialogue. Dive into a world of uninhibited expression, where your words become the catalyst for passionate encounters. With our discreet and secure environment, you can explore the art of sexting with confidence and indulge in the pleasure of connecting intimately.</p>
                    </>
                )
                break
            case '/omegle-adult-alternative':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - Free Omegle adult alternative</title>
                        <meta name="description" content="Experience an exhilarating Omegle adult alternative that goes beyond boundaries. Connect with like-minded individuals, engage in adult chat, explore webcam sex encounters, and indulge in thrilling exchanges. Join our platform for free and unleash your desires in a safe and inclusive environment." />
                    </Helmet>
                )
                setWelcomeText('Experience the best free Omegle adult alternative platform')
                setReadMoreContent(
                    <>
                        <p>Step into a world of limitless possibilities with our exciting Omegle adult alternative. Our platform offers a unique and captivating experience that caters to your desires for adult chat, webcam sex encounters, and unforgettable connections. Connect with like-minded individuals who share your passion and explore a new realm of pleasure that goes beyond boundaries.</p>
                        <p>Indulge in scintillating adult chat experiences within our Omegle adult alternative. Engage in intimate conversations, exchange seductive messages, and explore your fantasies with individuals who understand your desires. Whether you seek passionate connections, casual encounters, or intellectual exchanges, our platform provides a safe and inclusive environment where you can freely express yourself and unleash your deepest desires.</p>
                        <p>Ignite your senses and experience electrifying webcam sex encounters within our Omegle adult alternative. Connect with captivating individuals and engage in live webcam sessions that bring your fantasies to life. Explore a world of intimacy, voyeurism, and mutual pleasure as you indulge in sensual exchanges and unleash your wildest desires from the comfort of your own home.</p>
                        <p>Our Omegle adult alternative prioritizes your safety and fosters an inclusive environment for all. We strive to create a space where individuals can explore their desires without judgment or fear. Embrace the freedom of expressing your true self, connect with open-minded individuals, and experience the thrill of adventurous encounters within a secure platform. Join us today and be part of a vibrant community that embraces diversity and respect.</p>
                        <p>Unlock the gateway to exhilarating experiences by joining our Omegle adult alternative for free. Create an account and immerse yourself in a world of passionate connections, sensual exchanges, and unforgettable encounters. Our user-friendly platform ensures seamless navigation, allowing you to focus on exploring and indulging in the variety of experiences we offer. Join us today and unleash your desires in ways you've never imagined.</p>
                    </>
                )
                break
            case '/lesbian-chat':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - Free lesbian chat sex</title>
                        <meta name="description" content="Indulge in passionate lesbian chat sex experiences, connecting with like-minded individuals who share your desires. Engage in intimate exchanges, explore webcam encounters, and unleash your fantasies in a safe and inclusive environment. Join our platform for free and ignite your passions today." />
                    </Helmet>
                )
                setWelcomeText('Experience the best free lesbian chat sex platform')
                setReadMoreContent(
                    <>
                        <p>Immerse yourself in the world of lesbian chat sex and discover a realm of passionate connections that will leave you breathless. Our platform offers a safe and inclusive space where you can engage with like-minded individuals who share your desires for intimate exchanges. Connect with captivating women, explore your fantasies, and ignite your passions in ways that only lesbian chat sex can provide.</p>
                        <p>Experience the thrill of intimate exchanges with like-minded women in lesbian chat sex. Connect with individuals who understand and embrace your desires, engaging in seductive conversations that leave you craving for more. Whether you seek meaningful connections, steamy encounters, or intellectual exchanges, our platform provides a safe and inclusive environment where you can freely express yourself and explore the depths of your desires.</p>
                        <p>Explore the electrifying world of webcam encounters within lesbian chat sex. Connect with captivating women in live webcam sessions, where you can unleash your fantasies and indulge in mutual pleasure. Experience the thrill of voyeurism, mutual exploration, and uninhibited expression as you engage in sensual exchanges that cater to your desires. Allow yourself to be captivated by the intimate connections that can be forged through webcam encounters in lesbian chat sex.</p>
                        <p>At our platform, we prioritize your safety and foster an inclusive environment where all are welcomed. We provide a space where you can explore your desires in a supportive community that celebrates diversity and respect. Whether you are new to lesbian chat sex or an experienced explorer, our platform offers a secure space where you can connect, share, and unleash your passions with confidence.</p>
                        <p>Ignite your passions and join our platform for free to experience the world of lesbian chat sex. Create an account and immerse yourself in a community of women who are eager to explore and indulge in intimate connections. Our user-friendly interface ensures a seamless experience, allowing you to focus on connecting with like-minded individuals and embracing the sensual pleasures of lesbian chat sex. Join us today and unlock a world of passionate connections and unforgettable experiences.</p>
                    </>
                )
                break
            case '/gay-chat':
                setHelmetContent(
                    <Helmet>
                        <link rel="canonical" href={`https://projecteros.app${location.pathname}`} />
                        <title>Project Eros - Free gay chat sex</title>
                        <meta name="description" content="Immerse yourself in an electrifying world of free gay chat sensations. Connect with like-minded men, indulge in intimate exchanges, explore exhilarating webcam encounters, and ignite your deepest desires in a secure and inclusive environment. Join our platform for free and embark on a thrilling journey today." />
                    </Helmet>
                )
                setWelcomeText('Experience the best free gay chat sex platform')
                setReadMoreContent(
                    <>
                        <p>Step into a realm where unbridled desires find expression in the captivating world of free gay chat sensations. Our platform invites you to connect with like-minded men who share your passions and explore a multitude of intimate exchanges. Engage in seductive conversations, unleash your deepest fantasies, and ignite your desires through the thrilling experience of free gay chat sensations.</p>
                        <p>Dive into an unforgettable journey of intimate exchanges with compatible companions within the realm of free gay chat sensations. Discover men who understand and embrace your desires, engaging in passionate conversations that leave you yearning for more. Whether you seek casual encounters, genuine connections, or intellectual stimulation, our platform provides a safe and inclusive environment where you can freely express yourself and explore the depths of your desires.</p>
                        <p>Ignite your wildest fantasies through electrifying webcam encounters in the realm of free gay chat sensations. Connect with captivating men in live webcam sessions, where the boundaries of pleasure blur and uninhibited expression thrives. Experience the rush of voyeurism, mutual exploration, and sensual connections as you indulge in seductive exchanges. Allow yourself to be captivated by the allure of webcam encounters and the freedom to explore your desires.</p>
                        <p>Discover a safe haven for authentic connections within our platform dedicated to free gay chat sensations. We foster an inclusive environment where all individuals are celebrated and respected. Embrace the freedom to be your true self as you explore your desires in a supportive community. Whether you are new to the realm of free gay chat sensations or a seasoned adventurer, our platform provides a secure space to connect, share, and unleash your passions.</p>
                        <p>Embark on a thrilling journey of self-discovery and unbridled desires by joining our platform for free. Create an account and immerse yourself in a vibrant community of men eager to explore and indulge in the free gay chat sensations that await. With a user-friendly interface designed for seamless navigation, you can focus on forging connections and embracing the sensual pleasures of our platform. Join us today and unlock a world of passionate connections and unforgettable experiences.</p>
                    </>
                )
                break
        }

    }, [currentPath])

    useEffect(() => {

        const videoElem = document.querySelectorAll('.carousel-video');
        videoElem.forEach((video) => {
            video.play()
        })
        if (!sessionStorage.getItem('analytics_consent')) sessionStorage.setItem('analytics_consent', true)
        if (!sessionStorage.getItem('marketing_consent')) sessionStorage.setItem('marketing_consent', true)

    }, [])

    useEffect(() => {
        if (sessionStorage.getItem('tracking_consent') === 'true'){
            navigate('/home')
        }
    })

    function handleClick(e) {
        e.preventDefault()
        if (e.target.name === 'accept') {
            sessionStorage.setItem('tracking_consent', 'true')
            trackingEvents.tracking_consent()
            navigate('/home')
        }else if (e.target.name === 'customize') {
            navigate(`/${'welcome'}/privacy`)
            window.scrollTo(0,0)
        }
    }

    function toggleReadMore(e) {
        if (readMoreRef.current.style.display === 'none') {
            readMoreRef.current.style.display = 'flex'
            e.target.querySelector('svg').style.transform = 'rotate(180deg)'
            e.target.querySelector('span').textContent = 'Collapse'
        } else {
            readMoreRef.current.style.display = 'none'
            e.target.querySelector('svg').style.transform = 'rotate(0deg)'
            e.target.querySelector('span').textContent = 'Read more'
        }
    }

    return (
        <PopUp isCloseHidden={true} isBackHidden={true}>
            {helmetContent}
            <div className="welcome">
                <div className="welcome-cont">

                    <div className="carousel">
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/gay-1.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-woman-2.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-man-2.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/lesbian-1.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-man-3.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-woman-1.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/gay-1.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/lesbian-2.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-man-1.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-woman-3.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/gay-1.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-woman-2.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/straight-man-2.mp4`}></video></div>
                        <div className="carousel-media"> <video className='carousel-video' width="200" height="200" type="video/mp4" autoPlay playsInline muted loop src={`${process.env.REACT_APP_PUBLIC_URL}/assets/video/lesbian-1.mp4`}></video></div>
                    </div>
                    <div className="carousel-spacer"></div>

                    <svg className="welcome-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.034 16">
                        <path
                            d="M777.28,17.78h2a3.37,3.37,0,0,1,1.73.347,1.175,1.175,0,0,1,.567,1.08,1.368,1.368,0,0,1-.247.823,1.1,1.1,0,0,1-.65.432V20.5a1.331,1.331,0,0,1,.776.481,1.419,1.419,0,0,1,.239.855,1.465,1.465,0,0,1-.585,1.23,2.566,2.566,0,0,1-1.6.45h-2.23Zm1.545,2.194h.467a.845.845,0,0,0,.515-.137.477.477,0,0,0,.183-.408q0-.483-.729-.483h-.432Zm0,1.133v1.2h.545c.484,0,.721-.2.721-.611a.529.529,0,0,0-.193-.432.894.894,0,0,0-.564-.153Z"
                            transform="translate(-665.24 -15.217)" fill="#e2003b" />
                        <path d="M818.213,23.513h-3.4V17.78h3.4v1.243H816.36v.9h1.717v1.243H816.36V22.25h1.854Z"
                            transform="translate(-697.36 -15.217)" fill="#e2003b" />
                        <path d="M845.419,23.513H843.87V19.044h-1.4V17.78h4.344v1.267h-1.4Z" transform="translate(-721.033 -15.217)"
                            fill="#e2003b" />
                        <path
                            d="M878.135,23.376l-.288-1.075h-1.855l-.288,1.075H874l1.874-5.756h2.067l1.893,5.756Zm-.6-2.345-.246-.941q-.086-.314-.209-.812c-.084-.332-.144-.569-.163-.714-.024.134-.071.353-.144.659s-.222.908-.46,1.808Z"
                            transform="translate(-748.018 -15.08)" fill="#e2003b" />
                        <path
                            d="M778.752,11.872h-19.4a2.792,2.792,0,0,1-2.789-2.789V4.029a2.792,2.792,0,0,1,2.789-2.789h19.4a2.792,2.792,0,0,1,2.788,2.789V9.083a2.792,2.792,0,0,1-2.788,2.789Zm-19.4-9.963a2.123,2.123,0,0,0-2.12,2.12V9.083a2.122,2.122,0,0,0,2.12,2.119h19.4a2.122,2.122,0,0,0,2.119-2.119V4.029a2.123,2.123,0,0,0-2.119-2.12Z"
                            transform="translate(-647.506 -1.061)" fill="#e2003b" />
                        <path
                            d="M0,16.652V.91H3.707c2.505,0,3.827,1.349,3.827,3.9V6.488c0,2.566-1.348,3.813-4.123,3.813H1.433v6.351ZM3.412,9.07c1.91,0,2.691-.729,2.691-2.515V4.746c0-1.8-.739-2.6-2.4-2.6H1.433V9.07Z"
                            transform="translate(0 -0.779)" fill="#fff" />
                        <path
                            d="M74.788,16.652a2.958,2.958,0,0,1-.52-2.005V12.222c0-1.757-.886-2.474-3.057-2.474H69.573v6.9H68.14V.91h3.728c2.54,0,3.827,1.224,3.827,3.638V5.892a2.99,2.99,0,0,1-2.037,3.2,2.977,2.977,0,0,1,2.037,3.154v2.4a3.05,3.05,0,0,0,.389,1.763l.176.242ZM71.235,8.519c1.874,0,3.027-.408,3.027-2.429V4.636c0-1.7-.768-2.494-2.419-2.494h-2.27V8.519Z"
                            transform="translate(-58.318 -0.779)" fill="#fff" />
                        <path
                            d="M143.081,16c-2.479,0-3.9-1.479-3.9-4.056V4.055c0-2.577,1.415-4.055,3.9-4.055,2.514,0,3.952,1.477,3.952,4.055V11.95C147.033,14.527,145.594,16,143.081,16Zm0-14.775c-2.04,0-2.468,1.5-2.468,2.757v8.033c0,1.257.432,2.759,2.468,2.759,2.083,0,2.521-1.5,2.521-2.759V3.988c0-1.258-.438-2.757-2.521-2.757Z"
                            transform="translate(-119.118)" fill="#fff" />
                        <path
                            d="M209.271,16.669c-.19,0-.383,0-.577-.017l-.144-.007V15.411s.355.017.45.017c.238,0,.473-.014.7-.029,1.6-.11,2.283-.9,2.283-2.628V.91h1.433v11.8c0,2.553-1.169,3.843-3.576,3.945C209.647,16.664,209.46,16.669,209.271,16.669Z"
                            transform="translate(-178.489 -0.779)" fill="#fff" />
                        <path d="M259.32,16.652V.91h7.069V2.142h-5.637V8.055h4.68V9.288h-4.68v6.133h5.637v1.231Z"
                            transform="translate(-221.941 -0.779)" fill="#fff" />
                        <path
                            d="M328.253,16c-2.45,0-3.853-1.479-3.853-4.056V4.055C324.4,1.477,325.8,0,328.253,0s3.853,1.477,3.853,4.055V5.486h-1.382v-1.5c0-1.258-.425-2.757-2.45-2.757s-2.45,1.5-2.45,2.757v8.027c0,1.248.424,2.739,2.45,2.739s2.45-1.488,2.45-2.739V10.034h1.382V11.95C332.106,14.527,330.7,16,328.253,16Z"
                            transform="translate(-277.64)" fill="#fff" />
                        <path d="M393.818,16.652V2.142H390.34V.91h8.415V2.142h-3.5v14.51Z" transform="translate(-334.075 -0.779)"
                            fill="#fff" />
                        <path d="M484.767,8.5v2.205h-3.7v4.52h4.656v2.205H478.37V2h7.351V4.205h-4.656V8.5Z"
                            transform="translate(-409.416 -1.712)" fill="#fff" />
                        <path
                            d="M546.3,15.538V13.112c0-1.441-.541-1.963-1.764-1.963h-.931v6.285h-2.7V2h4.068c2.792,0,3.993,1.169,3.993,3.55V6.757a3.019,3.019,0,0,1-1.764,3.132c1.356.51,1.789,1.678,1.789,3.288v2.381a4.193,4.193,0,0,0,.288,1.874h-2.739A4.356,4.356,0,0,1,546.3,15.538Zm-2.7-6.595h1.054c1.009,0,1.616-.4,1.616-1.632V5.79c0-1.1-.415-1.586-1.371-1.586h-1.3Z"
                            transform="translate(-462.941 -1.712)" fill="#fff" />
                        <path
                            d="M611.782.76c2.646,0,4.092,1.411,4.092,3.88v8.027c0,2.469-1.441,3.882-4.092,3.882s-4.092-1.413-4.092-3.882V4.64C607.69,2.171,609.136.76,611.782.76Zm0,13.584c.858,0,1.4-.419,1.4-1.522V4.486c0-1.1-.539-1.521-1.4-1.521s-1.4.418-1.4,1.521v8.336C610.385,13.925,610.925,14.344,611.782,14.344Z"
                            transform="translate(-520.095 -0.65)" fill="#fff" />
                        <path
                            d="M677.672.76c2.622,0,3.97,1.411,3.97,3.88v.486h-2.547v-.64c0-1.1-.492-1.521-1.349-1.521s-1.348.418-1.348,1.521c0,3.171,5.27,3.771,5.27,8.182,0,2.469-1.374,3.882-4.019,3.882s-4.019-1.413-4.019-3.882v-.948h2.547v1.1c0,1.1.539,1.5,1.4,1.5s1.4-.4,1.4-1.5c0-3.171-5.268-3.771-5.268-8.182C673.7,2.171,675.05.76,677.672.76Z"
                            transform="translate(-576.53 -0.65)" fill="#fff" />
                    </svg>
                    <h1 className="welcome-title big-bold">Welcome to Project Eros</h1>
                    <h2 className="welcome-text med-regular">{welcomeText}</h2>
                    <div className="welcome-read-more-button" onClick={(e) => toggleReadMore(e)}>
                        <span>Read more</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </div>
                    <div className="welcome-read-more" ref={readMoreRef} style={{display: 'none'}}>
                        <span className="welcome-read-more-text sml-regular gray">{readMoreContent}</span>
                    </div>
                    <div className="welcome-privacy">
                        <h1 className="welcome-privacy-title">Privacy.</h1>
                        <span className="welcome-privacy-text sml-regular gray">By pressing "Agree & Continue" and proceeding you agree with our <a href="https://legal.projecteros.app/terms" target="_blank" rel="noopener noreferrer">Terms Of Service</a> and <a href="https://legal.projecteros.app/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>  and usage of <a href="https://legal.projecteros.app/data"  target="_blank" rel="noopener noreferrer">Cookies And Data</a> for site analytics and personalized advertisements.</span>
                        <span className="welcome-privacy-text sml-regular gray">Click "Customize" to review and edit <a href="https://legal.projecteros.app/data" target="_blank" rel="noopener noreferrer">Cookies And Data</a> options.</span>
                        <div className="welcome-button-cont">
                            <button type="button" name="customize" className="welcome-btn btn-red" onClick={(e) => handleClick(e)}>Customize</button>
                            <button type="button" name="accept" className="welcome-btn btn-red" onClick={(e) => handleClick(e)}>Agree & Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </PopUp>
     )
}

export default Welcome