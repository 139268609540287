import { useEffect, useState, createContext, useRef, lazy, Suspense } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import TopBar from './common/TopBar'
import ContentWarning from './common/ContentWarning'
import Welcome from './welcome/Welcome'
import Privacy from './welcome/Privacy'
import Loading from './common/Loading'
const Home = lazy(() => import('./home/Home'))
const Session = lazy(() => import('./session/Session'))


export const LocalStreamContext = createContext()

function App() {

  let location = useLocation()
  let navigate = useNavigate()

  const [ localStream, setLocalStream ] = useState()
  const [ mutedLocalStream, setMutedLocalStream ] = useState()
  const [ showContentWarning, setShowContentWarning ] = useState(!sessionStorage.getItem('mature_content_consent'))

  // Refs
  const playVideoAdRef = useRef()
  

  useEffect(() => {
    // Landin Page Query
    const queryParams = new URLSearchParams(location.search)
    const utm_source = queryParams.get('utm_source')
    const aclid = queryParams.get('aclid')
    if (!sessionStorage.getItem('utm_source') && utm_source) {
      sessionStorage.setItem('utm_source', utm_source)
      // sessionStorage.setItem('mature_content_consent', true)
      // setShowContentWarning(false)
    }
    if (!sessionStorage.getItem('aclid') && aclid) {
      sessionStorage.setItem('aclid', aclid)
    }
    // Cookie consent checker
    if (!sessionStorage.getItem('tracking_consent') && 
      ![
        '/welcome',
        '/welcome/privacy',
        '/chat-sex',
        '/webcam-sex',
        '/adult-chat',
        '/sexting',
        '/omegle-adult-alternative',
        '/lesbian-chat',
        '/gay-chat'
      ].includes(location.pathname)) {
        navigate('/welcome', { replace: true })
    }

  })

  return (
    <div className="App">
        <TopBar />
        <main id='main'>
          { showContentWarning ? <ContentWarning setShowContentWarning={setShowContentWarning}/> : null }
          <LocalStreamContext.Provider value={ { localStream, setLocalStream, mutedLocalStream, setMutedLocalStream } }>
            <Routes>
              <Route path='/welcome' element={ <Welcome/> } />
              <Route path='/welcome/privacy' element={ <Privacy /> } />

              <Route path='/chat-sex' element={ <Welcome/> } />
              <Route path='/webcam-sex' element={ <Welcome/> } />
              <Route path='/adult-chat' element={ <Welcome/> } />
              <Route path='/sexting' element={ <Welcome/> } />
              <Route path='/omegle-adult-alternative' element={ <Welcome/> } />
              <Route path='/lesbian-chat' element={ <Welcome/> } />
              <Route path='/gay-chat' element={ <Welcome/> } />

              <Route path='/home' element={<Suspense fallback={ <Loading /> }> <Home /> </Suspense>} />
              <Route path='/' element={<Suspense fallback={ <Loading /> }> <Home /> </Suspense>} />

              <Route path='/session' element={<Suspense fallback={ <Loading /> }> <Session playVideoAdRef={playVideoAdRef} /> </Suspense>} />
            </Routes>
          </LocalStreamContext.Provider>
        </main>
    </div>
  )  
}

export default App
