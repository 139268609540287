const { v4: uuidv4 } = require('uuid')

module.exports.tracking_consent = () => {

    const analytics_consent_given = sessionStorage.getItem('analytics_consent')
    const marketing_consent_given = sessionStorage.getItem('marketing_consent')

    if (analytics_consent_given === 'false') {
        window.gtag('event', 'analytics_consent_refused')
    } else {
        window.gtag('event', 'analytics_consent_given')
    }

    if (marketing_consent_given === 'false') {
        window.gtag('event', 'marketing_consent_refused')
    } else {
        window.gtag('event', 'marketing_consent_given')
    }

}

module.exports.first_searching = () => {
    const { user_genitals, user_GE, partner_genitals, partner_GE } = JSON.parse(sessionStorage.getItem('matchingPref'))
    let user_type

    if (user_genitals === 'penis') {
        if (user_GE === 'masculine') {
            if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'straight_man'
            } else if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'gay_man'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_man'
            } else {
                user_type = 'misc_man'
            }
        } else if (user_GE === 'feminine') {
            if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'straight_trans_woman'
            } else if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'gay_trans_woman'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_trans_woman'
            } else {
                user_type = 'misc_trans_woman'
            }
        } else if (user_GE === 'non-binary') {
            if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'straight_nb_penis'
            } else if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'gay_nb_penis'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_nb_penis'
            } else {
                user_type = 'misc_nb_penis'
            }
        }
    } else if (user_genitals === 'vagina') {
        if (user_GE === 'masculine') {
            if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'straight_trans_man'
            } else if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'gay_trans_man'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_trans_man'
            } else {
                user_type = 'misc_trans_man'
            }
        } else if (user_GE === 'feminine') {
            if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'straight_woman'
            } else if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'gay_woman'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_woman'
            } else {
                user_type = 'misc_woman'
            }
        } else if (user_GE === 'non-binary') {
            if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'straight_nb_vagina'
            } else if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'gay_nb_vagina'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_nb_vagina'
            } else {
                user_type = 'misc_nb_vagina'
            }
        }
    } else if (user_genitals === 'intersex') {
        if (user_GE === 'masculine') {
            if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'straight_intersex_man'
            } else if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'gay_intersex_man'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_intersex_man'
            } else {
                user_type = 'misc_intersex_man'
            }
        } else if (user_GE === 'feminine') {
            if (!partner_genitals.includes('vagina') && partner_genitals.includes('penis') && partner_GE.includes('masculine') ) {
                user_type = 'straight_intersex_woman'
            } else if (!partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.includes('feminine') ) {
                user_type = 'gay_intersex_woman'
            } else if (partner_genitals.length >= 2 && partner_genitals.includes('penis') && partner_genitals.includes('vagina') && partner_GE.length >= 2 && partner_GE.includes('feminine') && partner_GE.includes('masculine')) {
                user_type = 'bi_intersex_woman'
            } else {
                user_type = 'misc_intersex_woman'
            }
        } else if (user_GE === 'non-binary') {
            user_type = 'intersex_nb'
        }
    }

    const utm_source = sessionStorage.getItem('utm_source')
    const aclid = sessionStorage.getItem('aclid')
    if (utm_source && utm_source === 'trafficjunky') {
        const unique_id = uuidv4()
        const conversionCode = document.createElement('img')
        conversionCode.src = `https://ads.trafficjunky.net/tj_ads_pt?a=1000420341&member_id=1004292881&cb=${unique_id}&cti=${unique_id}&ctv=0&ctd=first_searching` + (aclid ? `&aclid=${aclid}` : '')
        conversionCode.width = 1
        conversionCode.height = 1
        conversionCode.style.display = 'none'
        document.body.appendChild(conversionCode)
    }

    window.gtag('event', user_type)

}

module.exports.first_match = () => {
    window.gtag('event', 'first_match')
}

module.exports.start_searching = () => {
    window.gtag('event', 'start_searching')
}

module.exports.failed_match = () => {
    window.gtag('event', 'failed_match')
}

module.exports.start_match = () => {
    window.gtag('event', 'start_match')
}

module.exports.skip_match = () => {
    window.gtag('event', 'skip_match')
}

module.exports.was_skipped_match = () => {
    window.gtag('event', 'was_skipped_match')
}

module.exports.in_match_10s = () => {
    window.gtag('event', 'in_match_10s')
}

module.exports.in_match_30s = () => {
    window.gtag('event', 'in_match_30s')
}

module.exports.in_match_1m = () => {
    window.gtag('event', 'in_match_1m')
}

module.exports.in_match_5m = () => {
    window.gtag('event', 'in_match_5m')
}

module.exports.in_match_10m = () => {
    window.gtag('event', 'in_match_10m')
}

module.exports.start_video_ad = () => {
    window.gtag('event', 'start_video_ad')
}

module.exports.failed_video_ad = () => {
    window.gtag('event', 'failed_video_ad')
}

